<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="transactionHeaders"
            :items="transactions"
            item-key="transactionId"
            hide-default-footer
            single-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="blue darken-4"
              >
                <v-toolbar-title
                  class="white--text"
                >
                  {{ $t('titleMaterialMovementRecent') }}
                </v-toolbar-title>
                <v-spacer />
                <download-excel
                  :data="transactions"
                  name="Transactions"
                >
                  <v-btn
                    text
                    class="white--text"
                  >
                    <v-icon>
                      mdi-download
                    </v-icon>
                    Download
                  </v-btn>
                </download-excel>
              </v-toolbar>
            </template>
            <template v-slot:item.transactionDate="{ item }">
              <div>
                {{ item.transactionDate | moment('LL') }}
              </div>
            </template>
            <template v-slot:item.originQuantity="{ item }">
              <div>
                {{ Number(item.originQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.originRQuantity="{ item }">
              <div>
                {{ Number(item.originRQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.targetQuantity="{ item }">
              <div>
                {{ Number(item.targetQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.targetRQuantity="{ item }">
              <div>
                {{ Number(item.targetRQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <!--
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                @click="onEditItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                class="mr-2"
                @click="onDeleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import transactionAPI from '@/services/transaction'
  // import customColors from '@/styles/appcolors'
  export default {
    name: 'UserSetting',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      DownloadExcel: () => import('vue-json-excel'),
    },
    data: function () {
      return {
        appTitle: this.$t('titleMaterialRecent'),
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcMaterial'),
            disabled: true,
            href: '/material',
          },
          {
            text: this.$t('bcMatRecent'),
            disabled: true,
            href: '/material/recent',
          },
        ],
        /*
        pagestyles: {
          breadcrumb: customColors.MainPage.breadcrumb,
          breadcrumbColor: customColors.MainPage.breadcrumbColor,
          pageFrameColor: customColors.MaterialRecent.pageFrameColor,
        },
        */
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        transactionHeaders: [
          {
            text: this.$t('col-recent-TransactionId'),
            align: 'end`',
            value: 'transactionId',
            sortable: true,
            class: 'transactioncol display-1',
            width: '5%',
          },
          {
            text: this.$t('col-recent-TransactionDate'),
            align: 'start',
            value: 'transactionDate',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-Type'),
            align: 'start',
            value: 'transactionTypeText',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-OriginProduct'),
            align: 'start',
            value: 'originProductName',
            sortable: true,
            class: 'transactioncol display-1',
          },
          {
            text: this.$t('col-recent-TargetPartner'),
            align: 'start',
            value: 'targetPartnerName',
            sortable: true,
            class: 'transactioncol display-1',
          },
          {
            text: this.$t('col-recent-OriginQuantity'),
            align: 'end',
            value: 'originQuantity',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-OriginRQuantity'),
            align: 'end',
            value: 'originRQuantity',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-TargetQuantity'),
            align: 'end',
            value: 'targetQuantity',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-TargetRQuantity'),
            align: 'end',
            value: 'targetRQuantity',
            sortable: true,
            class: 'transactioncol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-Status'),
            align: 'start',
            value: 'statusCodeText',
            sortable: true,
            class: 'transactioncol display-1',
            width: '8%',
          },
          /* {
            text: this.$t('col-recent-actions'),
            align: 'center',
            value: 'actions',
            sortable: false,
            class: 'transactioncol display-1',
          }, */
        ],
        transactions: [],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      transactionAPI.getMaterialMovementRecent()
        .then(response => {
          this.transactions = response.data
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
  }
</script>
