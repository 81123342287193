var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumb-bar',{attrs:{"title":_vm.appTitle,"breadcrumbs":_vm.breadcrumbs}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.transactionHeaders,"items":_vm.transactions,"item-key":"transactionId","hide-default-footer":"","single-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"blue darken-4"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('titleMaterialMovementRecent'))+" ")]),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.transactions,"name":"Transactions"}},[_c('v-btn',{staticClass:"white--text",attrs:{"text":""}},[_c('v-icon',[_vm._v(" mdi-download ")]),_vm._v(" Download ")],1)],1)],1)]},proxy:true},{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.transactionDate,'LL'))+" ")])]}},{key:"item.originQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.originQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}},{key:"item.originRQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.originRQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}},{key:"item.targetQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.targetQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}},{key:"item.targetRQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.targetRQuantity).toFixed(2))+" "+_vm._s(item.unitofMeasureText)+" ")])]}}])})],1)],1)],1),_c('error-dialog',{attrs:{"dialog":_vm.showErrorDialog,"title":_vm.$t('errorTitleProcess'),"message":_vm.errorMessage},on:{"update:dialog":function($event){_vm.showErrorDialog = false}}}),_c('process-overlay',{attrs:{"processing":_vm.modelProcessing}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }